import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
import { EmailAddressChangeRequest } from 'types/emailAddressChangeRequest';
export type ConfirmEmailAddressChangePage = {
  loginEmail: string | undefined | null;
  formError: string | undefined | null;
  emailAddressChangeRequestRemoteData: RemoteData<EmailAddressChangeRequest>;
  successMessage: string | undefined | null;
};
const defaultState = {
  loginEmail: null,
  formError: null,
  emailAddressChangeRequestRemoteData:
    uninitializedRemoteData<EmailAddressChangeRequest>(),
  successMessage: null,
};
export default function confirmEmailAddressChangePageReducer(
  state: ReducerInputState<ConfirmEmailAddressChangePage> = defaultState,
  action: Action,
): ConfirmEmailAddressChangePage {
  switch (action.type) {
    case 'VALIDATE_EMAIL_ADDRESS_CHANGE_REQUEST_START':
      return Object.assign({}, state, {
        formError: null,
        emailAddressChangeRequestRemoteData:
          unfetchedRemoteData<EmailAddressChangeRequest>(),
      });
    case 'VALIDATE_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS':
      return Object.assign({}, state, {
        loginEmail: action.loginEmail,
        formError: null,
        emailAddressChangeRequestRemoteData:
          fetchedRemoteData<EmailAddressChangeRequest>(
            action.emailAddressChangeRequest,
          ),
      });
    case 'VALIDATE_EMAIL_ADDRESS_CHANGE_REQUEST_FAIL':
      return Object.assign({}, state, {
        formError: null,
        emailAddressChangeRequestRemoteData:
          remoteDataError<EmailAddressChangeRequest>(action.message),
      });
    case 'AUTHORIZE_EMAIL_ADDRESS_CHANGE_SUCCESS':
      return Object.assign({}, state, {
        formError: null,
        successMessage: action.message,
      });
    case 'AUTHORIZE_EMAIL_ADDRESS_CHANGE_FAIL':
      return Object.assign({}, state, {
        formError: action.message,
      });
    case 'CANCEL_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS':
      return Object.assign({}, state, {
        formError: null,
      });
    case 'CANCEL_EMAIL_ADDRESS_CHANGE_REQUEST_FAIL':
      return Object.assign({}, state, {
        formError: action.message,
      });
    default:
      return state;
  }
}
