import backend from 'app/backend';
import { currentState, dispatch } from 'app/store';
export default function ShowInvoice(invoiceNumber: string) {
  fetchInvoice(invoiceNumber);
  showInvoice(invoiceNumber);
}
function fetchInvoice(invoiceNumber: string) {
  if (currentState().invoicesByNumber[invoiceNumber]) {
    return;
  }
  dispatch({
    type: 'FETCH_INVOICE_START',
    invoiceNumber,
  });
  const args = {};
  const filter = {
    Number: invoiceNumber,
  };
  return backend
    .dispatch('GetInvoiceForSessionAccount', args, filter, {
      requireSessionUser: true,
    })
    .then((response) => {
      if (!response.Success) {
        dispatch({
          type: 'FETCH_INVOICE_FAIL',
          invoiceNumber,
          message: response.Error,
        });
        return;
      }
      dispatch({
        type: 'FETCH_INVOICE_SUCCESS',
        invoiceNumber,
        invoice: response.Result,
      });
    })
    .catch((error) => {
      if (error instanceof backend.NoSessionUserError) {
        dispatch({
          type: 'FETCH_INVOICE_FAIL',
          invoiceNumber,
          message: 'You must be signed in to view your invoices.',
          error,
        });
        return;
      }
      dispatch({
        type: 'FETCH_INVOICE_FAIL',
        invoiceNumber,
        message: error.message,
      });
    });
}
function showInvoice(invoiceNumber: string) {
  dispatch({
    type: 'SHOW_INVOICE',
    invoiceNumber,
  });
}
