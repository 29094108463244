import { dispatch } from 'app/store';
import backend from 'app/backend';
import { Account } from 'types/account';
export default function FetchUserAccounts() {
  backend.dispatch('GetAccounts').then((response) => {
    if (!response.Success) {
      return;
    }

    response.Result.forEach((account: Account) => {
      dispatch({
        type: 'FETCH_ACCOUNT_SUCCESS',
        account,
      });
    });
  });
}
