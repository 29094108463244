import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Order } from 'types/order';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
type FetchedValue = Array<Order['Number']>;
export type Orders = RemoteData<FetchedValue>;
const defaultState = unfetchedRemoteData<FetchedValue>();
export default function ordersReducer(
  state: ReducerInputState<Orders> = defaultState,
  action: Action,
): Orders {
  switch (action.type) {
    case 'FETCH_SESSION_ORDERS_START':
      return unfetchedRemoteData<FetchedValue>();
    case 'FETCH_SESSION_ORDERS_SUCCESS':
      return fetchedRemoteData<FetchedValue>(action.fetchedNumbers);
    case 'FETCH_SESSION_ORDERS_FAIL':
      return remoteDataError<FetchedValue>(action.message);
    case 'FETCH_SESSION_ORDERS_ABORT':
      return uninitializedRemoteData<FetchedValue>();
    case 'TRADE_IN_LICENSE_SUCCESS':
      if (state.value != null) {
        return fetchedRemoteData<FetchedValue>(
          [action.order.Number].concat(state.value),
        );
      } else {
        return fetchedRemoteData<FetchedValue>([action.order.Number]);
      }
    case 'SIGN_OUT_SUCCESS':
      return uninitializedRemoteData<FetchedValue>();
    default:
      return state;
  }
}
