import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
import { EmailAddressChangeRequest } from 'types/emailAddressChangeRequest';
export type ConfirmNewEmailAddressPage = {
  formError: string | undefined | null;
  emailAddressChangeRequestRemoteData: RemoteData<EmailAddressChangeRequest>;
};
const defaultState = {
  formError: null,
  emailAddressChangeRequestRemoteData:
    uninitializedRemoteData<EmailAddressChangeRequest>(),
};
export default function confirmNewEmailAddressPageReducer(
  state: ReducerInputState<ConfirmNewEmailAddressPage> = defaultState,
  action: Action,
): ConfirmNewEmailAddressPage {
  switch (action.type) {
    case 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_START':
      return Object.assign({}, state, {
        formError: null,
        emailAddressChangeRequestRemoteData:
          unfetchedRemoteData<EmailAddressChangeRequest>(),
      });
    case 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS':
      return Object.assign({}, state, {
        formError: null,
        emailAddressChangeRequestRemoteData:
          fetchedRemoteData<EmailAddressChangeRequest>(
            action.emailAddressChangeRequest,
          ),
      });
    case 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_FAIL':
      return Object.assign({}, state, {
        formError: null,
        emailAddressChangeRequestRemoteData:
          remoteDataError<EmailAddressChangeRequest>(action.message),
      });
    case 'FINALIZE_EMAIL_ADDRESS_CHANGE_SUCCESS':
      return Object.assign({}, state, {
        formError: null,
      });
    case 'FINALIZE_EMAIL_ADDRESS_CHANGE_FAIL':
      return Object.assign({}, state, {
        formError: action.message,
      });
    case 'CANCEL_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS':
      return Object.assign({}, state, {
        formError: null,
      });
    case 'CANCEL_EMAIL_ADDRESS_CHANGE_REQUEST_FAIL':
      return Object.assign({}, state, {
        formError: action.message,
      });
    default:
      return state;
  }
}
