import { Order } from 'types/order';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  RemoteData,
  remoteDataError,
  fetchedRemoteData,
  unfetchedRemoteData,
} from 'app/store/remoteData';
export type OrdersByID = {
  [number: Order['ID']]: RemoteData<Order>;
};
const defaultState = {};
export default function ordersByIDReducer(
  state: ReducerInputState<OrdersByID> = defaultState,
  action: Action,
): OrdersByID {
  if (action.type === 'FETCH_ORDER_SUCCESS') {
    return Object.assign({}, state, {
      [action.order.ID]: fetchedRemoteData<Order>(action.order),
    });
  }
  if (action.type === 'FETCH_SESSION_ORDERS_SUCCESS') {
    const { ordersByNumber, fetchedNumbers } = action;
    const fetchedOrders: {
      [id: Order['ID']]: RemoteData<Order>;
    } = {};
    fetchedNumbers.forEach((number) => {
      const order = ordersByNumber[number];
      fetchedOrders[order.ID] = fetchedRemoteData<Order>(order);
    });
    return Object.assign({}, state, fetchedOrders);
  }
  if (action.type === 'FETCH_ORDER_BY_ID_START') {
    return Object.assign({}, state, {
      [action.orderID]: unfetchedRemoteData<Order>(),
    });
  }
  if (action.type === 'FETCH_ORDER_BY_ID_FAIL') {
    return Object.assign({}, state, {
      [action.orderID]: remoteDataError<Order>(action.message, action.error),
    });
  }
  if (action.type === 'TRADE_IN_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.order.ID]: fetchedRemoteData<Order>(action.order),
    });
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
