import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Order } from 'types/order';
export type OrderPage = {
  showOrderNumber: Order['Number'] | undefined | null;
};
const defaultState = {
  showOrderNumber: null,
};
export default function orderPageReducer(
  state: ReducerInputState<OrderPage> = defaultState,
  action: Action,
): OrderPage {
  switch (action.type) {
    case 'SHOW_ORDER':
      return Object.assign({}, state, {
        showOrderNumber: action.orderNumber,
      });
    default:
      return state;
  }
}
