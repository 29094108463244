import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import cartPageReducer, { CartPage } from './cartPage';
import checkoutPageReducer, { CheckoutPage } from './checkoutPage';
import licensePageReducer, { LicensePage } from './licensePage';
import orderPageReducer, { OrderPage } from './orderPage';
import invoicePageReducer, { InvoicePage } from './invoicePage';
import signInPageReducer, { SignInPage } from './signInPage';
import confirmEmailAddressChangePageReducer, {
  ConfirmEmailAddressChangePage,
} from './confirmEmailAddressChangePage';
import confirmNewEmailAddressPageReducer, {
  ConfirmNewEmailAddressPage,
} from './confirmNewEmailAddressPage';
import resetPasswordPageReducer, {
  ResetPasswordPage,
} from './resetPasswordPage';
import upgradeToV5PageReducer, { UpgradeToV5Page } from './upgradeToV5Page';
export type Pages = {
  cartPage: CartPage;
  checkoutPage: CheckoutPage;
  licensePage: LicensePage;
  orderPage: OrderPage;
  invoicePage: InvoicePage;
  signInPage: SignInPage;
  confirmEmailAddressChangePage: ConfirmEmailAddressChangePage;
  confirmNewEmailAddressPage: ConfirmNewEmailAddressPage;
  resetPasswordPage: ResetPasswordPage;
  upgradeToV5Page: UpgradeToV5Page;
};
const defaultState = {
  cartPage: undefined,
  checkoutPage: undefined,
  licensePage: undefined,
  orderPage: undefined,
  invoicePage: undefined,
  signInPage: undefined,
  confirmEmailAddressChangePage: undefined,
  confirmNewEmailAddressPage: undefined,
  resetPasswordPage: undefined,
  upgradeToV5Page: undefined,
};
export default function pagesReducer(
  state: ReducerInputState<Pages> = defaultState,
  action: Action,
): Pages {
  return {
    cartPage: cartPageReducer(state.cartPage, action),
    checkoutPage: checkoutPageReducer(state.checkoutPage, action),
    licensePage: licensePageReducer(state.licensePage, action),
    orderPage: orderPageReducer(state.orderPage, action),
    invoicePage: invoicePageReducer(state.invoicePage, action),
    signInPage: signInPageReducer(state.signInPage, action),
    confirmEmailAddressChangePage: confirmEmailAddressChangePageReducer(
      state.confirmEmailAddressChangePage,
      action,
    ),
    confirmNewEmailAddressPage: confirmNewEmailAddressPageReducer(
      state.confirmNewEmailAddressPage,
      action,
    ),
    resetPasswordPage: resetPasswordPageReducer(
      state.resetPasswordPage,
      action,
    ),
    upgradeToV5Page: upgradeToV5PageReducer(state.upgradeToV5Page, action),
  };
}
