import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { License } from 'types/license';
export type LicenseTradeIn = {
  licenseSerial: License['Serial'] | undefined | null;
  tradingIn: boolean;
  tradedIn: boolean;
  tradeInError: string | undefined | null;
  viaCart: boolean;
};
const defaultState = {
  licenseSerial: null,
  tradingIn: false,
  tradedIn: false,
  tradeInError: null,
  viaCart: false,
};
export default function licenseTradeInReducer(
  state: ReducerInputState<LicenseTradeIn> = defaultState,
  action: Action,
): LicenseTradeIn {
  switch (action.type) {
    case 'BEGIN_LICENSE_TRADE_IN':
      return Object.assign({}, state, {
        licenseSerial: action.license.Serial,
        tradingIn: false,
        tradedIn: false,
        viaCart: action.viaCart || false,
        tradeInError: null,
      });
    case 'TRADE_IN_LICENSE_START':
      return Object.assign({}, state, {
        tradedIn: false,
        tradeInError: null,
        tradingIn: true,
      });
    case 'TRADE_IN_LICENSE_FAIL':
      return Object.assign({}, state, {
        tradedIn: false,
        tradeInError: action.message,
        tradingIn: false,
      });
    case 'ADD_LICENSE_TRADE_IN_TO_CART_FAIL':
      return Object.assign({}, state, {
        tradedIn: false,
        tradeInError: action.message,
        tradingIn: false,
      });
    case 'ADD_LICENSE_TRADE_IN_TO_CART_SUCCESS':
      return Object.assign({}, state, {
        tradedIn: false,
        tradeInError: null,
        tradingIn: false,
      });
    case 'TRADE_IN_LICENSE_SUCCESS':
      return Object.assign({}, state, {
        tradedIn: true,
        tradeInError: null,
        tradingIn: false,
      });
    default:
      return state;
  }
}
