import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import modeReducer, { Mode } from './mode';
import offlineActivationReducer, {
  OfflineActivation,
} from './offlineActivation';
import licenseSeatDeactivationReducer, {
  LicenseSeatDeactivation,
} from './licenseSeatDeactivation';
import licenseTradeInReducer, { LicenseTradeIn } from './licenseTradeIn';
export type Modal = {
  mode: Mode;
  offlineActivation: OfflineActivation;
  licenseSeatDeactivation: LicenseSeatDeactivation;
  licenseTradeIn: LicenseTradeIn;
};
const defaultState = {
  mode: undefined,
  offlineActivation: undefined,
  licenseSeatDeactivation: undefined,
  licenseTradeIn: undefined,
};
export default function modalReducer(
  state: ReducerInputState<Modal> = defaultState,
  action: Action,
): Modal {
  return {
    mode: modeReducer(state.mode, action),
    offlineActivation: offlineActivationReducer(
      state.offlineActivation,
      action,
    ),
    licenseSeatDeactivation: licenseSeatDeactivationReducer(
      state.licenseSeatDeactivation,
      action,
    ),
    licenseTradeIn: licenseTradeInReducer(state.licenseTradeIn, action),
  };
}
