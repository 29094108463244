import backend from 'app/backend';
import store, { dispatch } from 'app/store';
import * as errorReporter from 'app/errorReporter';
function AddTestLineItem(
  AmountCentsUSD: number,
  Description: string = 'Test LineItem',
) {
  dispatch({
    type: 'ADD_PRODUCT_TO_CART_START',
  });
  backend
    .dispatch('AddTestLineItemToCart', {
      AmountCentsUSD,
      Description,
    })
    .then((response) => {
      if (!response.Success) {
        // The backend may respond with updates to ShopInfo, suggesting an
        // error that was caused by stale shop info.
        // This mode of failure is common after we grant edu pricing
        // to an account, and the user is interacting with a shop page
        // that still shows non-edu pricing.
        dispatch({
          type: 'ADD_PRODUCT_TO_CART_FAIL',
          message: response.Error,
          updatedShopInfo: response.Result,
        });
        return;
      }
      dispatch({
        type: 'FETCH_SHOP_INFO_SUCCESS',
        updatedShopInfo: response.Result,
      });
    });
}
function ApiDispatch(
  action: string,
  args?: {
    [key: string | number]: any;
  },
  filter?: {
    [key: string]: any;
  },
) {
  return backend.dispatch(action, args, filter);
}
export default {
  AddTestLineItem,
  ApiDispatch,
  store,
  errorReporter,
};
