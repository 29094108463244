import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Order } from 'types/order';
export type PaymentInstrument = 'creditCard' | 'payPal';
export type CheckoutPage = {
  newUserPassword: string;
  // what we'll set the user's password to if their account doesn't have one yet
  checkoutError: string | undefined | null;
  storeCreditError: string | undefined | null;
  checkoutErrorsByField: {
    [fieldName: string]: string;
  };
  checkoutInProgress: boolean;
  checkoutComplete: boolean;
  completedOrderNumber: Order['Number'] | undefined | null;
  lockCart: boolean;
  qlabUseSurveyResponse: string;
  paymentInstrument: PaymentInstrument;
};
const defaultState = {
  newUserPassword: '',
  checkoutError: null,
  storeCreditError: null,
  checkoutErrorsByField: {},
  checkoutInProgress: false,
  checkoutComplete: false,
  completedOrderNumber: null,
  lockCart: false,
  qlabUseSurveyResponse: '',
  paymentInstrument: 'creditCard',
};
export default function checkoutPageReducer(
  state: ReducerInputState<CheckoutPage> = defaultState,
  action: Action,
): CheckoutPage {
  switch (action.type) {
    case 'NEW_CART':
      return Object.assign({}, state, {
        checkoutComplete: false,
        completedOrderNumber: null,
        lockCart: false,
      });
    case 'CHECKOUT_FAIL':
      return Object.assign({}, state, {
        checkoutError: action.message,
        checkoutErrorsByField: action.fieldErrors || {},
        checkoutInProgress: false,
        checkoutComplete: false,
        lockCart: false,
      });
    case 'CLEAR_CHECKOUT_ERRORS':
      return {
        ...state,
        checkoutError: null,
        checkoutErrorsByField: {},
      };
    case 'CHECKOUT_LOCK_CART':
      return Object.assign({}, state, {
        lockCart: true,
      });
    case 'CHECKOUT_UNLOCK_CART':
      return Object.assign({}, state, {
        lockCart: false,
      });
    case 'CHECKOUT_START':
      return Object.assign({}, state, {
        checkoutError: null,
        checkoutErrorsByField: {},
        checkoutInProgress: true,
        checkoutComplete: false,
        completedOrderNumber: null,
        lockCart: true,
      });
    case 'CHECKOUT_SUCCESS':
      return Object.assign({}, state, {
        checkoutError: null,
        checkoutErrorsByField: {},
        checkoutInProgress: false,
        checkoutComplete: true,
        completedOrderNumber: action.completedOrderNumber,
        lockCart: true,
        qlabUseSurveyResponse: '',
      });
    case 'UPDATE_CHECKOUT_NEW_USER_PASSWORD':
      return {
        ...state,
        newUserPassword: action.password,
      };
    case 'UPDATE_QLAB_USE_SURVEY_RESPONSE':
      return Object.assign({}, state, {
        qlabUseSurveyResponse: action.value,
      });
    case 'ADD_STORE_CREDIT_TO_CART_FAIL':
      return Object.assign({}, state, {
        storeCreditError: action.message,
      });
    case 'ADD_STORE_CREDIT_TO_CART_START':
      return Object.assign({}, state, {
        storeCreditError: null,
      });

    // Because this will update the cart, this should clear error messages, it
    case 'FETCH_SHOP_INFO_SUCCESS':
      return Object.assign({}, state, {
        checkoutError: null,
        checkoutErrorsByField: {},
        // Should also update the QLab use survey response from the value in
        // the cart. If updated shop info doesn't include a survey response
        // just use the existing value, fallback to empty string.
        qlabUseSurveyResponse:
          action.updatedShopInfo.Cart.QLabUseSurveyResponse ||
          state.qlabUseSurveyResponse ||
          '',
      });
    case 'SELECT_PAYMENT_INSTRUMENT':
      return Object.assign({}, state, {
        paymentInstrument: action.paymentInstrument,
        checkoutError: null,
      });
    default:
      return state;
  }
}
