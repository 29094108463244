import { RentalConversion } from 'types/rentalConversion';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { fetchedRemoteData, RemoteData } from 'app/store/remoteData';
export type RentalConversionsByID = {
  [ID: RentalConversion['ID']]: RemoteData<RentalConversion>;
};
const defaultState = {};
export default function rentalConversionsByIDReducer(
  state: ReducerInputState<RentalConversionsByID> = defaultState,
  action: Action,
): RentalConversionsByID {
  if (action.type === 'FETCH_ACCOUNT_RENTAL_CONVERSIONS_SUCCESS') {
    const fetchedRentalConversions: RentalConversionsByID = {};
    action.rentalConversions.forEach((rentalConversion) => {
      fetchedRentalConversions[rentalConversion.ID] =
        fetchedRemoteData<RentalConversion>(rentalConversion);
    });
    return Object.assign({}, state, fetchedRentalConversions);
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
