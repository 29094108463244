import { Action } from 'app/store/actions';
export type Mode =
  | 'OfflineActivation'
  | 'LicenseSeatDeactivation'
  | 'LicenseTradeIn'
  | null;
const defaultState = null;
export default function modeReducer(
  state: Mode = defaultState,
  action: Action,
): Mode {
  switch (action.type) {
    case 'BEGIN_LICENSE_TRADE_IN':
      return 'LicenseTradeIn';
    case 'BEGIN_OFFLINE_ACTIVATION':
      return 'OfflineActivation';
    case 'SHOW_LICENSE_SEAT_DEACTIVATION_MODAL':
      return 'LicenseSeatDeactivation';
    case 'CLOSE_MODAL':
      return null;
    default:
      return state;
  }
}
