import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
export type LicensePage = {
  showLicenseSerial: string | undefined | null;
  seatTableColumnToggles: {
    [x: string]: boolean;
  };
};
const defaultState = {
  showLicenseSerial: null,
  seatTableColumnToggles: {
    ActivationNote: true,
    ActivationEndDate: false,
    SeatID: false,
    KeyCode: false,
    MachineID: true,
    MachineName: true,
    ComputerSerial: true,
    ComputerModel: true,
    ActivationStatus: false,
    ActivationTimestamp: false,
  },
};
export default function licensePageReducer(
  state: ReducerInputState<LicensePage> = defaultState,
  action: Action,
): LicensePage {
  switch (action.type) {
    case 'SHOW_LICENSE':
      return Object.assign({}, state, {
        showLicenseSerial: action.licenseSerial,
      });
    case 'SET_SEATS_TABLE_COLUMN_VISIBILITY':
      return Object.assign({}, state, {
        seatTableColumnToggles: {
          ...state.seatTableColumnToggles,
          [action.columnName]: action.value,
        },
      });
    default:
      return state;
  }
}
