import moment from 'moment';
const appLog = [];
export function info(...args) {
  appLog.push({
    type: 'info',
    time: moment().format(),
    message: args,
  });
  if (ENV.BUILD_ENV === 'development') {
    return console.log.apply(null, args); // eslint-disable-line no-console
  }
}
export function warn(...args) {
  appLog.push({
    type: 'warn',
    time: moment().format(),
    message: args,
  });
  return console.warn.apply(null, args); // eslint-disable-line no-console
}

// use this to log messages in a development environment,
// these messages won't be included in log dumps e.g. to honeybadger
export function debug(...args) {
  if (ENV.BUILD_ENV === 'development') {
    return console.log.apply(null, args); // eslint-disable-line no-console
  }
}
export function dumpAll() {
  return appLog;
}
export function dumpTail(len) {
  return appLog.slice(-1 * len);
}
