import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Invoice } from 'types/invoice';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
type FetchedValue = Array<Invoice['Number']>;
export type Invoices = RemoteData<FetchedValue>;
const defaultState = unfetchedRemoteData<FetchedValue>();
export default function ordersReducer(
  state: ReducerInputState<Invoices> = defaultState,
  action: Action,
): Invoices {
  switch (action.type) {
    case 'FETCH_SESSION_INVOICES_START':
      return unfetchedRemoteData<FetchedValue>();
    case 'FETCH_SESSION_INVOICES_SUCCESS':
      return fetchedRemoteData<FetchedValue>(action.fetchedNumbers);
    case 'FETCH_SESSION_INVOICES_FAIL':
      return remoteDataError<FetchedValue>(action.message);
    case 'FETCH_SESSION_INVOICES_ABORT':
      return uninitializedRemoteData<FetchedValue>();
    case 'SIGN_OUT_SUCCESS':
      return uninitializedRemoteData<FetchedValue>();
    default:
      return state;
  }
}
