import braintreeClient from 'braintree-web/client';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type BraintreeClient = RemoteData<braintreeClient>;
const defaultState = uninitializedRemoteData<braintreeClient>();
export default function braintreeClientReducer(
  state: ReducerInputState<BraintreeClient> = defaultState,
  action: Action,
): BraintreeClient {
  switch (action.type) {
    case 'INITIALIZE_BRAINTREE_START':
      return unfetchedRemoteData<braintreeClient>();
    case 'INITIALIZE_BRAINTREE_SUCCESS':
      return fetchedRemoteData<braintreeClient>(action.braintreeClient);
    case 'INITIALIZE_BRAINTREE_FAIL':
      return remoteDataError<braintreeClient>(action.message);
    default:
      return state;
  }
}
