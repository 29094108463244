import { navigate } from 'gatsby';
import { dispatch } from 'app/store';
import backend, { LOGIN_DELAY_MS } from 'app/backend';
import FetchUserAccounts from './FetchUserAccounts';
import NotifyUser from './NotifyUser';
export default function CreateAccountViaFancyLogin(args: {
  Token: string;
  onSuccessRedirectTo?: string | null;
}) {
  const { Token, onSuccessRedirectTo } = args;
  dispatch({
    type: 'SIGN_IN_START',
  });
  dispatch({
    type: 'PROCESSING_FANCY_LOGIN_START',
    reason: 'Creating your account',
  });
  backend
    .dispatch('CreateAccountWithFancyLogin', {
      App: 'Web',
      Token,
    })
    .then((response) => {
      setTimeout(() => {
        dispatch({
          type: 'PROCESSING_FANCY_LOGIN_END',
        });
        if (!response.Success) {
          dispatch({
            type: 'SIGN_IN_FAIL',
            message: response.Error,
          });
          return;
        }
        dispatch({
          type: 'SIGN_IN_SUCCESS',
          user: response.Result,
          authenticatingUser: response.Result,
        });
        NotifyUser({
          message: 'Your account has been created.',
          timeoutMs: 5000,
        });
        FetchUserAccounts();
        if (onSuccessRedirectTo != null && onSuccessRedirectTo !== '') {
          navigate(onSuccessRedirectTo, {
            replace: true,
          });
        } else {
          navigate('/account/', {
            replace: true,
          });
        }
      }, LOGIN_DELAY_MS);
    });
}
