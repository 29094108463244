import { Action } from 'app/store/actions';
import { User } from 'types/user';
import { Account } from 'types/account';
import { ReducerInputState } from 'app/store/types';
import {
  RemoteData,
  fetchedRemoteData,
  pendingRemoteData,
  updatingRemoteData,
  uninitializedRemoteData,
} from 'app/store/remoteData';
type UserID = User['ID'];
type AccountID = Account['ID'];
export type Session = {
  // if userID.value != null, the user is signed in
  userID: RemoteData<UserID>;
  // note: accountID.value may be set by ShopInfo, but that doesn't
  // mean the user is signed in.
  accountID: RemoteData<AccountID>;
  // the ID for the user whose credentials were used to sign in
  authenticatingUserID: RemoteData<UserID>;
  // true once the backend has fetched the session
  initialized: boolean;
  // true once the session has failed to initialize
  initializationFailed: boolean;
  // optional session init failure message
  initializationFailedError: string | undefined | null;
};
const defaultState = {
  userID: uninitializedRemoteData<UserID>(),
  accountID: uninitializedRemoteData<AccountID>(),
  authenticatingUserID: uninitializedRemoteData<UserID>(),
  initialized: false,
  initializationFailed: false,
  initializationFailedError: null,
};
export default function sessionReducer(
  state: ReducerInputState<Session> = defaultState,
  action: Action,
): Session {
  switch (action.type) {
    case 'SESSION_INITIALIZED':
      return Object.assign({}, state, {
        initialized: true,
      });
    case 'SIGN_IN_START':
      return Object.assign({}, state, {
        userID: pendingRemoteData<UserID>(),
        authenticatingUserID: pendingRemoteData<UserID>(),
      });
    case 'CREATE_USER_ACCOUNT_SUCCESS':
      return Object.assign({}, state, {
        userID: fetchedRemoteData<UserID>(action.user.ID),
        accountID: fetchedRemoteData<AccountID>(action.user.DefaultAccountID),
        authenticatingUserID: fetchedRemoteData<UserID>(action.user.ID),
      });
    case 'SIGN_IN_SUCCESS':
      return Object.assign({}, state, {
        userID: fetchedRemoteData<UserID>(action.user.ID),
        accountID: fetchedRemoteData<AccountID>(action.user.DefaultAccountID),
        authenticatingUserID: fetchedRemoteData<UserID>(
          action.authenticatingUser.ID,
        ),
      });
    case 'SIGN_OUT_SUCCESS':
      return Object.assign({}, state, {
        userID: uninitializedRemoteData<UserID>(),
        authenticatingUserID: uninitializedRemoteData<UserID>(),
        accountID: uninitializedRemoteData<AccountID>(),
      });
    case 'FETCH_SHOP_INFO_SUCCESS':
      return Object.assign({}, state, {
        accountID: action.updatedShopInfo.Account
          ? fetchedRemoteData<AccountID>(action.updatedShopInfo.Account.ID)
          : state.accountID,
      });
    case 'SWITCH_USER_START':
      return Object.assign({}, state, {
        userID: updatingRemoteData<UserID>(state.userID.value),
      });
    case 'SWITCH_USER_FAIL':
      return Object.assign({}, state, {
        userID: fetchedRemoteData<UserID>(action.restoreUser.ID),
      });
    case 'SWITCH_USER_SUCCESS':
      return Object.assign({}, state, {
        userID: fetchedRemoteData<UserID>(action.user.ID),
      });
    case 'INITIALIZE_SESSION_FAIL':
      return Object.assign({}, state, {
        initializationFailed: true,
        initializationFailedError: action.message,
      });
    default:
      return state;
  }
}
