import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Cart as BackendCart } from 'types/cart';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  updatingRemoteData,
  fetchedRemoteData,
  remoteDataUpdateError,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type Cart = RemoteData<BackendCart>;
const defaultState = uninitializedRemoteData<BackendCart>();
export default function cartReducer(
  state: ReducerInputState<Cart> = defaultState,
  action: Action,
): Cart {
  switch (action.type) {
    case 'INITIALIZE_CART_START':
      return unfetchedRemoteData<BackendCart>();
    case 'INITIALIZE_CART_FAIL':
      return remoteDataError<BackendCart>(action.message);
    case 'ADD_STORE_CREDIT_TO_CART_START':
      return updatingRemoteData<BackendCart>(state.value);
    case 'ADD_LICENSE_TRADE_IN_TO_CART_START':
      return updatingRemoteData<BackendCart>(state.value);
    case 'ADD_PRODUCT_TO_CART_START':
      return updatingRemoteData<BackendCart>(state.value);
    case 'ADD_LICENSE_TRADE_IN_TO_CART_FAIL':
      if (state.value != null) {
        return fetchedRemoteData<BackendCart>(state.value);
      } else {
        return unfetchedRemoteData<BackendCart>();
      }
    case 'ADD_STORE_CREDIT_TO_CART_FAIL':
      if (state.value != null) {
        return fetchedRemoteData<BackendCart>(state.value);
      } else {
        return unfetchedRemoteData<BackendCart>();
      }
    case 'ADD_PRODUCT_TO_CART_FAIL':
      return remoteDataUpdateError<BackendCart>(
        action.updatedShopInfo.Cart,
        action.message,
      );
    case 'CHANGE_CART_LINE_ITEM_START':
      return updatingRemoteData<BackendCart>(state.value);
    case 'CHANGE_CART_LINE_ITEM_FAIL':
      return remoteDataUpdateError(state.value, action.message);
    case 'CHANGE_CART_LINE_ITEM_SUCCESS':
      return fetchedRemoteData<BackendCart>(action.updatedCart);
    case 'CHECKOUT_FAIL':
      if (action.updatedCart == null) {
        return state;
      }
      return fetchedRemoteData<BackendCart>(action.updatedCart);
    case 'REMOVE_CART_LINE_ITEM_START':
      return updatingRemoteData<BackendCart>(state.value);
    case 'REMOVE_CART_LINE_ITEM_FAIL':
      return remoteDataUpdateError<BackendCart>(state.value, action.message);
    case 'FETCH_SHOP_INFO_SUCCESS':
      return fetchedRemoteData<BackendCart>(action.updatedShopInfo.Cart);
    case 'UPDATE_CART_FOR_CHECKOUT_SUCCESS':
      if (action.updatedShopInfo.Cart == null) {
        return state;
      }
      return fetchedRemoteData<BackendCart>(action.updatedShopInfo.Cart);
    case 'ALLOW_CHECKOUT_NEW_ACCOUNT':
      return fetchedRemoteData<BackendCart>(action.updatedCart);
    case 'NEW_CART':
      return fetchedRemoteData<BackendCart>(action.newCart);
    default:
      return state;
  }
}
