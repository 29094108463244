import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import cartReducer, { Cart } from './cart';
import rentalStatsReducer, { RentalStats } from './rentalStats';
import productsReducer, { Products } from './products';
import catalogReducer, { Catalog } from './catalog';
import braintreeClientReducer, { BraintreeClient } from './braintreeClient';
import legacyLicensesReducer, { LegacyLicenses } from './legacyLicenses';
import createAccountOnCheckoutReducer, {
  CreateAccountOnCheckout,
} from './createAccountOnCheckout';
import validatorsReducer, { Validators } from './validators';
export type Shop = {
  products: Products;
  catalog: Catalog;
  cart: Cart;
  rentalStats: RentalStats;
  braintreeClient: BraintreeClient;
  legacyLicenses: LegacyLicenses;
  createAccountOnCheckout: CreateAccountOnCheckout;
  validators: Validators;
};
const defaultState = {
  products: undefined,
  catalog: undefined,
  cart: undefined,
  rentalStats: undefined,
  braintreeClient: undefined,
  legacyLicenses: undefined,
  createAccountOnCheckout: undefined,
};
export default function shopReducer(
  state: ReducerInputState<Shop> = defaultState,
  action: Action,
): Shop {
  return {
    products: productsReducer(state.products, action),
    cart: cartReducer(state.cart, action),
    catalog: catalogReducer(state.catalog, action),
    rentalStats: rentalStatsReducer(state.rentalStats, action),
    braintreeClient: braintreeClientReducer(state.braintreeClient, action),
    legacyLicenses: legacyLicensesReducer(state.legacyLicenses, action),
    createAccountOnCheckout: createAccountOnCheckoutReducer(
      state.createAccountOnCheckout,
      action,
    ),
    validators: validatorsReducer(state.validators, action),
  };
}
