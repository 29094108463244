import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Invoice } from 'types/invoice';
export type InvoicePage = {
  showInvoiceNumber: Invoice['Number'] | undefined | null;
  newUserPassword: string;
  checkoutError: string | undefined | null;
  checkoutErrorsByField: {
    [fieldName: string]: string;
  };
  checkoutInProgress: boolean;
  checkoutComplete: boolean;
};
const defaultState = {
  newUserPassword: '',
  showInvoiceNumber: null,
  checkoutErrorsByField: {},
  checkoutInProgress: false,
  checkoutComplete: false,
};
export default function invoicePageReducer(
  state: ReducerInputState<InvoicePage> = defaultState,
  action: Action,
): InvoicePage {
  switch (action.type) {
    case 'SHOW_INVOICE':
      return Object.assign({}, state, {
        showInvoiceNumber: action.invoiceNumber,
        checkoutInProgress: false,
        checkoutComplete: false,
        checkoutError: null,
        checkoutErrorsByField: {},
      });
    case 'INVOICE_CHECKOUT_START':
      return Object.assign({}, state, {
        checkoutInProgress: true,
        checkoutComplete: false,
        checkoutError: null,
        checkoutErrorsByField: {},
      });
    case 'INVOICE_CHECKOUT_SUCCESS':
      return Object.assign({}, state, {
        checkoutInProgress: false,
        checkoutComplete: true,
        checkoutError: null,
        checkoutErrorsByField: {},
      });
    case 'INVOICE_CHECKOUT_FAIL':
      return Object.assign({}, state, {
        checkoutInProgress: false,
        checkoutComplete: false,
        checkoutError: action.message,
        checkoutErrorsByField: action.fieldErrors || {},
      });
    case 'SELECT_PAYMENT_INSTRUMENT':
      return Object.assign({}, state, {
        checkoutError: null,
      });
    case 'UPDATE_INVOICE_NEW_USER_PASSWORD':
      return {
        ...state,
        newUserPassword: action.password,
      };
    default:
      return state;
  }
}
