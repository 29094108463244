import { createStore, applyMiddleware, compose } from 'redux';
import appReducers, { AppState } from 'app/reducers';
import { Action } from './actions';
import { logger } from './middleware';

export { AppState } from 'app/reducers';

const middlewares = [];
let composeEnhancers = compose;
if (typeof window !== 'undefined') {
  middlewares.push(logger);
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}
const store = createStore(
  appReducers,
  composeEnhancers(applyMiddleware(...middlewares)),
);
export function currentState(): AppState {
  return store.getState();
}
export function dispatch(action: Action) {
  store.dispatch(action);
}
export default store;
