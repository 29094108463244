import { Action } from 'app/store/actions';
export type CreateAccountOnCheckout = {
  email: string;
  name: string;
  error: string | undefined | null;
  valid: boolean;
};
const defaultState = {
  email: '',
  name: '',
  error: null,
  valid: false,
};
export default function createAccountOnCheckoutReducer(
  state: CreateAccountOnCheckout = defaultState,
  action: Action,
) {
  switch (action.type) {
    case 'FETCH_SHOP_INFO_SUCCESS':
      return Object.assign({}, state, {
        email: action.updatedShopInfo.Cart.NewUserLoginEmail,
        name: action.updatedShopInfo.Cart.NewUserAccountName,
      });
    case 'UPDATE_CART_FOR_CHECKOUT_SUCCESS':
      if (action.updatedShopInfo.Cart == null) {
        return state;
      }
      return Object.assign({}, state, {
        email: action.updatedShopInfo.Cart.NewUserLoginEmail,
        name: action.updatedShopInfo.Cart.NewUserAccountName,
      });
    case 'UPDATE_CHECKOUT_NEW_ACCOUNT':
      return Object.assign({}, state, {
        email: action.email,
      });
    case 'ALLOW_CHECKOUT_NEW_ACCOUNT':
      return Object.assign({}, state, {
        error: null,
        valid: true,
      });
    case 'SHOW_CHECKOUT_NEW_ACCOUNT_ERROR':
      return Object.assign({}, state, {
        error: action.error,
        valid: false,
      });
    default:
      return state;
  }
}
