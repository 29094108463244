import { dispatch } from 'app/store';
import backend from 'app/backend';
export default function ValidateEmailAddressChangeRequest(
  RequestedEmailAddress?: string | null,
  FinalizationToken?: string | null,
) {
  dispatch({
    type: 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_START',
  });
  backend
    .dispatch('ValidateAuthorizedEmailAddressChangeRequest', {
      RequestedEmailAddress,
      FinalizationToken,
    })
    .then((response) => {
      if (!response.Success) {
        dispatch({
          type: 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_FAIL',
          message: response.Error,
        });
        return;
      }
      dispatch({
        type: 'VALIDATE_AUTHORIZED_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS',
        emailAddressChangeRequest: response.Result,
      });
    });
}
