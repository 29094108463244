//See: https://www.gatsbyjs.org/docs/browser-apis/

import appDebugger from 'app/debugger';
import router from 'app/router';
import wrapWithProvider from './wrapWithProvider';
import * as errorReporter from 'app/errorReporter';
import { initCSRFTokenAndSession } from 'app/backend';
import 'whatwg-fetch';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import 'url-search-params-polyfill';

import './src/styles/tailwind-global.css';

import CloseModal from 'app/actionCreators/CloseModal';
import GetShopInfo from 'app/actionCreators/GetShopInfo';

export function onClientEntry() {
  // NB: this function should not cause app state changes or initialization
  // that could affect react page rendering. Changing app state while the page
  // may not yet be finished rendering has previously caused pages to render
  // improperly, with bizarre and broken layouts.
  //
  // For one-off state initialization, invoke in onInitialClientRender.

  // report errors for production/staging builds
  const Honeybadger = require('@honeybadger-io/js');
  Honeybadger.configure({
    apiKey: ENV.HONEYBADGER_API_KEY,
    environment: ENV.HONEYBADGER_ENV,
    revision: '6', // increment this for bugfixes
  });

  Honeybadger.beforeNotify(notice => {
    const ignorePatterns = [
      // we expect these errors to appear after we deploy, because users on the site
      // will be attempting to load old versions of pages that have been removed
      /ChunkLoadError/i,
    ];

    if (ignorePatterns.some(pattern => pattern.test(notice.message))) {
      return false;
    }
  });

  errorReporter.init(Honeybadger);

  // mount debugger for development and staging
  if (ENV.BUILD_ENV === 'development' || ENV.BUILD_ENV === 'staging') {
    window.f53debugger = appDebugger;
  }
}

export function onRouteUpdate(location) {
  router.execute(location.location.pathname);

  if (location.location.hash && !/^docs/.test(location.location.pathname)) {
    setTimeout(() => {
      const offset = getTargetOffset(location.location.hash);
      if (offset !== null) {
        window.scrollTo(0, offset);
      }
    }, 100);
  }

  // Refresh shop info on every page navigation, except on authentication callback pages
  if (!/^\/account\/register\//.test(location.location.pathname)) {
    GetShopInfo({ forceRefresh: true });
  }

  // Close any modals if the user navigates away from current page
  CloseModal();
}

const offsetY = 80;

const getTargetOffset = (hash) => {
  const id = window.decodeURI(hash.replace(`#`, ``));
  if (id !== ``) {
    const element = document.getElementById(id);
    if (element) {
      return element.offsetTop - offsetY;
    }
  }
  return null;
};

export function onInitialClientRender() {
  initCSRFTokenAndSession();
  setTimeout(() => {
    const offset = getTargetOffset(window.location.hash);
    if (offset !== null && !/^docs/.test(location.location)) {
      window.scrollTo(0, offset);
    }
  }, 100);
}

export function shouldUpdateScroll({ routerProps: { location } }) {
  const offset = getTargetOffset(location.hash);
  return offset !== null ? [0, offset] : true;
}

export const wrapRootElement = wrapWithProvider;
