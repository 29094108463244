import { Account } from 'types/account';
import { StoreCreditChange } from 'types/storeCreditChange';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  RemoteData,
  pendingRemoteData,
  fetchedRemoteData,
  remoteDataError,
} from 'app/store/remoteData';
export type StoreCreditChangesByAccountID = {
  [number: Account['ID']]: RemoteData<Array<StoreCreditChange>>;
};
type StoreCreditChanges = Array<StoreCreditChange>;
const defaultState = {};
export default function storeCreditChangesByAccountIDReducer(
  state: ReducerInputState<StoreCreditChangesByAccountID> = defaultState,
  action: Action,
): StoreCreditChangesByAccountID {
  switch (action.type) {
    case 'FETCH_ACCOUNT_STORE_CREDIT_CHANGES_START':
      return Object.assign({}, state, {
        [action.account.ID]: pendingRemoteData<StoreCreditChanges>(),
      });
    case 'FETCH_ACCOUNT_STORE_CREDIT_CHANGES_FAIL':
      return Object.assign({}, state, {
        [action.account.ID]: remoteDataError<StoreCreditChanges>(
          action.message,
        ),
      });
    case 'FETCH_ACCOUNT_STORE_CREDIT_CHANGES_SUCCESS':
      return Object.assign({}, state, {
        [action.account.ID]: fetchedRemoteData<StoreCreditChanges>(
          action.storeCreditChanges,
        ),
      });
    default:
      return state;
  }
}
