import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
import { License } from 'types/license';
export type Licenses = RemoteData<Array<string>>;
const defaultState = unfetchedRemoteData<Array<string>>();
export default function licensesReducer(
  state: ReducerInputState<Licenses> = defaultState,
  action: Action,
): Licenses {
  let actionLicenseSerial: License['Serial'] | undefined | null = null;
  switch (action.type) {
    case 'TRADE_IN_LICENSE_SUCCESS':
      actionLicenseSerial = action.license.Serial;
      // remove the traded-in license serial from the list of the current
      // user's licenses
      return state.value != null
        ? fetchedRemoteData<Array<string>>(
            state.value.filter((serial) => {
              return serial !== actionLicenseSerial;
            }),
          )
        : state;
    case 'FETCH_SESSION_LICENSES_START':
      return unfetchedRemoteData<Array<string>>();
    case 'FETCH_SESSION_LICENSES_SUCCESS':
      return fetchedRemoteData<Array<string>>(action.fetchedSerials);
    case 'FETCH_SESSION_LICENSES_FAIL':
      return remoteDataError<Array<string>>(action.message);
    case 'FETCH_SESSION_LICENSES_ABORT':
      return uninitializedRemoteData<Array<string>>();
    case 'SIGN_OUT_SUCCESS':
      return uninitializedRemoteData<Array<string>>();
    default:
      return state;
  }
}
