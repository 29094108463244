import * as log from 'app/log';
import { withRetries } from 'utils/retry';

// https://docs.honeybadger.io/lib/javascript/guides/reporting-errors.html
type ErrorReporterExtras = {
  message?: string | null;
  name?: string | null;
  component?: string | null;
  action?: string | null;
  context?: {
    [key: string]: any;
  };
  fingerprint?: string | null;
  params?: {
    [key: string]: any;
  };
  cookies?: {
    [key: string]: any;
  };
};
type ErrorReporter = {
  notify: (err: Error | string, extras?: ErrorReporterExtras | null) => void;
  setContext: (context: { [key: string]: any }) => void;
};
let honeybadger: ErrorReporter | undefined | null;

// call this with Honeybadger in onClientEntry
export function init(honeybadgerInstance: ErrorReporter) {
  if (honeybadger != null) {
    throw new Error('error reporter already initialized');
  }
  log.info('initializing error reporter', honeybadgerInstance);
  honeybadger = honeybadgerInstance;
}
export function notify(
  error: Error | string,
  extras?: ErrorReporterExtras | null,
) {
  if (ENV.BUILD_ENV === 'production' || ENV.BUILD_ENV === 'staging') {
    withRetries({
      delayMs: 500,
      maxRetries: 6,
      description: 'error report to honeybadger',
      fn: () => {
        if (honeybadger == null) {
          throw new Error(
            `Honeybadger not ready. Error not reported: ${JSON.stringify(error)}`,
          );
        }
        if (!extras) {
          extras = {};
        }
        if (!extras.context) {
          extras.context = {};
        }
        const autoContext = {
          logTail: log.dumpTail(50),
        };
        extras.context = Object.assign({}, extras.context, autoContext);
        if (error instanceof Error && extras.context.name == null) {
          extras.context.name = error.name;
        }
        honeybadger.notify(error, extras);
      },
    });
  } else {
    log.warn(error);
  }
}
export function setContext(context: { [key: string]: any }) {
  withRetries({
    delayMs: 500,
    maxRetries: 6,
    description: 'setting honeybadger error reporting context',
    fn: () => {
      if (honeybadger == null) {
        throw new Error(
          `Context not set, error reporter not configured: ${JSON.stringify(context)}`,
        );
      }
      honeybadger.setContext(context);
    },
  });
}
if (typeof window !== 'undefined') {
  window.__testErrorReporter = function () {
    notify(new Error('This is a test of the frontend error reporter.'));
  };
}
