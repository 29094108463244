import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { RentalStats as BackendRentalStats } from 'types/rentalStats';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type RentalStats = RemoteData<BackendRentalStats>;
const defaultState = uninitializedRemoteData<BackendRentalStats>();
export default function rentalStatsReducer(
  state: ReducerInputState<RentalStats> = defaultState,
  action: Action,
): RentalStats {
  switch (action.type) {
    case 'INITIALIZE_RENTAL_STATS_START':
      return unfetchedRemoteData<BackendRentalStats>();
    case 'INITIALIZE_RENTAL_STATS_FAIL':
      return remoteDataError<BackendRentalStats>(action.message);
    case 'FETCH_SHOP_INFO_SUCCESS':
      return fetchedRemoteData<BackendRentalStats>(
        action.updatedShopInfo.RentalStats,
      );
    case 'SIGN_OUT_SUCCESS':
      return unfetchedRemoteData<BackendRentalStats>();
    default:
      return state;
  }
}
