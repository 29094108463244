import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  remoteDataError,
  fetchedRemoteData,
  RemoteData,
} from 'app/store/remoteData';
import { LegacyLicenses as BackendLegacyLicenses } from 'types/legacyLicenses';
export type LegacyLicenses = RemoteData<BackendLegacyLicenses>;
const defaultState = uninitializedRemoteData<BackendLegacyLicenses>();
export default function legacyLicensesReducer(
  state: ReducerInputState<LegacyLicenses> = defaultState,
  action: Action,
): LegacyLicenses {
  switch (action.type) {
    case 'FETCH_LEGACY_LICENSES_START':
      return unfetchedRemoteData<BackendLegacyLicenses>();
    case 'FETCH_LEGACY_LICENSES_FAIL':
      return remoteDataError<BackendLegacyLicenses>(action.message);
    case 'FETCH_LEGACY_LICENSES_SUCCESS':
      return fetchedRemoteData<BackendLegacyLicenses>(action.legacyLicenses);
    case 'FETCH_SHOP_INFO_SUCCESS':
      return fetchedRemoteData<BackendLegacyLicenses>(
        action.updatedShopInfo.LegacyLicenses,
      );
    case 'SIGN_OUT_SUCCESS':
      return defaultState;
    default:
      return state;
  }
}
