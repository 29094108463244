import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
import { License } from 'types/license';
import { TradeInValue } from 'types/tradeInValue';
export type LicenseTradeInValueBySerial = {
  [x: License['Serial']]: RemoteData<TradeInValue> | undefined | null;
};
const defaultState = {};
export default function licenseTradeInReducer(
  state: ReducerInputState<LicenseTradeInValueBySerial> = defaultState,
  action: Action,
): LicenseTradeInValueBySerial {
  switch (action.type) {
    case 'FETCH_LICENSE_TRADE_IN_VALUE_START':
      return Object.assign({}, state, {
        [action.licenseSerial]: unfetchedRemoteData<TradeInValue>(),
      });
    case 'FETCH_LICENSE_TRADE_IN_VALUE_SUCCESS':
      return Object.assign({}, state, {
        [action.licenseSerial]: fetchedRemoteData<TradeInValue>(
          action.tradeInValue,
        ),
      });
    case 'FETCH_LICENSE_TRADE_IN_VALUE_FAIL':
      return Object.assign({}, state, {
        [action.licenseSerial]: remoteDataError<TradeInValue>(action.message),
      });
    default:
      return state;
  }
}
