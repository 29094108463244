import { AppState } from 'app/reducers';
import { User } from 'types/user';
import { Account } from 'types/account';
import { License } from 'types/license';
import { RemoteData } from 'app/store/remoteData';

// unwraps the session account remote data, returns null if anything is uninitialized or pending
function getSessionAccount(appState: AppState): Account | undefined | null {
  const sessionAccountID = appState.session.accountID.value;
  const accountRemoteData =
    sessionAccountID != null ? appState.accountsByID[sessionAccountID] : null;
  return accountRemoteData && accountRemoteData.value;
}

// unwraps the session user remote data, returns null if anything is uninitialized or pending
function getSessionUser(appState: AppState): User | undefined | null {
  const sessionUserID = appState.session.userID.value;
  const userRemoteData =
    sessionUserID != null ? appState.usersByID[sessionUserID] : null;
  return userRemoteData && userRemoteData.value;
}

// unwraps the session sign in user remote data
function getAuthenticatingUser(appState: AppState): User | undefined | null {
  const authenticatingUserID = appState.session.authenticatingUserID.value;
  const remoteData =
    authenticatingUserID != null
      ? appState.usersByID[authenticatingUserID]
      : null;
  return remoteData && remoteData.value;
}
function currentUserLicenses(appState: AppState): Array<RemoteData<License>> {
  const currentUserLicenseSerials = appState.currentUser.licenses;
  const licenses: Array<RemoteData<License>> =
    currentUserLicenseSerials.value != null
      ? currentUserLicenseSerials.value.map((licenseSerial) => {
          return appState.licensesBySerial[licenseSerial];
        })
      : [];
  return licenses;
}
export {
  getSessionAccount,
  getSessionUser,
  getAuthenticatingUser,
  currentUserLicenses,
};
