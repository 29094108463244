import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type ResetPasswordPage = {
  resetPasswordResult: RemoteData<string>;
};
const defaultState = {
  resetPasswordResult: uninitializedRemoteData<string>(),
};
export default function resetPasswordPageReducer(
  state: ReducerInputState<ResetPasswordPage> = defaultState,
  action: Action,
): ResetPasswordPage {
  switch (action.type) {
    case 'RESET_PASSWORD_START':
      return Object.assign({}, state, {
        resetPasswordResult: unfetchedRemoteData<string>(),
      });
    case 'RESET_PASSWORD_SUCCESS':
      return Object.assign({}, state, {
        resetPasswordResult: fetchedRemoteData<string>(action.message),
      });
    case 'RESET_PASSWORD_FAIL':
      return Object.assign({}, state, {
        resetPasswordResult: remoteDataError<string>(action.message),
      });
    case 'SIGN_OUT_SUCCESS':
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
}
