import * as log from 'app/log';
export function logger(store) {
  return (next) => {
    return (action) => {
      log.info('dispatching:', action);
      const result = next(action);
      log.debug('next state:', store.getState());
      return result;
    };
  };
}
