import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  fetchedRemoteData,
  RemoteData,
} from 'app/store/remoteData';
export type Recaptcha = {
  enabled: RemoteData<boolean>;
  apiIsAvailable: boolean | undefined | null;
};
const defaultState = {
  enabled: uninitializedRemoteData<boolean>(),
  apiIsAvailable: null,
};
export default function recaptchaReducer(
  state: ReducerInputState<Recaptcha> = defaultState,
  action: Action,
): Recaptcha {
  switch (action.type) {
    case 'FETCH_SHOP_INFO_SUCCESS':
      return Object.assign({}, state, {
        enabled: fetchedRemoteData(
          action.updatedShopInfo.EnableRecaptcha || false,
        ),
      });
    case 'SET_RECAPTCHA_API_AVAILABILITY':
      return {
        ...state,
        apiIsAvailable: action.availability,
      };
    default:
      return state;
  }
}
