import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { License, LicenseSeat } from 'types/license';
export type OfflineActivation = {
  license: License | undefined | null;
  licenseSeat: LicenseSeat | undefined | null;
  activating: boolean;
  activationError: string | undefined | null;
};
const defaultState = {
  license: null,
  licenseSeat: null,
  activating: false,
  activationError: null,
};
export default function offlineActivationReducer(
  state: ReducerInputState<OfflineActivation> = defaultState,
  action: Action,
): OfflineActivation {
  switch (action.type) {
    case 'BEGIN_OFFLINE_ACTIVATION':
      return Object.assign({}, state, {
        license: action.license,
        licenseSeat: action.licenseSeat,
      });
    case 'SUBMIT_OFFLINE_ACTIVATION_START':
      return Object.assign({}, state, {
        activating: true,
      });
    case 'SUBMIT_OFFLINE_ACTIVATION_FAIL':
      return Object.assign({}, state, {
        activating: false,
        activationError: action.message,
      });
    case 'SUBMIT_OFFLINE_ACTIVATION_SUCCESS':
      return Object.assign({}, state, {
        activating: false,
        activationError: null,
      });
    case 'CLOSE_MODAL':
      return Object.assign({}, state, {
        license: null,
        licenseSeat: null,
      });
    default:
      return state;
  }
}
