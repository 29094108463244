import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { LicenseSeat, LicenseSeatActivation } from 'types/license';
export type LicenseSeatDeactivation = {
  licenseSeat: LicenseSeat | undefined | null;
  licenseSeatActivation: LicenseSeatActivation | undefined | null;
};
const defaultState = {
  licenseSeat: null,
  licenseSeatActivation: null,
};
export default function licenseSeatDeactivationReducer(
  state: ReducerInputState<LicenseSeatDeactivation> = defaultState,
  action: Action,
): LicenseSeatDeactivation {
  switch (action.type) {
    case 'SHOW_LICENSE_SEAT_DEACTIVATION_MODAL':
      return Object.assign({}, state, {
        licenseSeat: action.licenseSeat,
        licenseSeatActivation: action.licenseSeatActivation,
      });
    default:
      return state;
  }
}
