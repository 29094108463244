import * as log from 'app/log';
const withRetriesDefaultArgs = {
  attempts: 0,
  delayMs: 300,
};
type WithRetryArgs = {
  delayMs?: number;
  maxRetries: number;
  attempts?: number;
  description: string;
  fn: (a?: any) => any;
};
export function withRetries(args: WithRetryArgs): void {
  args = Object.assign({}, withRetriesDefaultArgs, args);
  if (args.attempts == null) {
    log.warn(
      `retrying ${args.description} is disabled due to unknown number of previous attempts. attempting now.`,
    );
    try {
      args.fn();
    } catch (err) {
      log.warn(args.description, ': ', err);
    }
    return;
  }

  if (args.attempts >= args.maxRetries) {
    try {
      args.fn();
    } catch (err) {
      log.warn(args.description, ': ', err);
    }
    return;
  }

  try {
    args.fn();
  } catch (err) {
    setTimeout(() => {
      log.info(
        `retrying ${args.description}. attempt ${(args.attempts || 0) + 1} of ${args.maxRetries}`,
      );
      const retryArgs = Object.assign({}, args, {
        attempts: (args.attempts || 0) + 1,
      });
      withRetries(retryArgs);
    }, args.delayMs);
  }
}
