import { navigate } from 'gatsby';
import { dispatch } from 'app/store';
import backend from 'app/backend';
import GetShopInfo from './GetShopInfo';
import NotifyUser from './NotifyUser';
export default function VerifyLoginEmail(args: {
  Token: string;
  onSuccessRedirectTo?: string | null;
}) {
  const { Token, onSuccessRedirectTo } = args;
  backend
    .dispatch('VerifyLoginEmail', {
      Token,
    })
    .then((response) => {
      if (response.Success) {
        dispatch({
          type: 'SIGN_IN_SUCCESS',
          user: response.Result,
          authenticatingUser: response.Result,
        });
        GetShopInfo({
          forceRefresh: true,
        });
        NotifyUser({
          message: 'Your email address has been verified.',
        });
        if (onSuccessRedirectTo != null && onSuccessRedirectTo !== '') {
          navigate(onSuccessRedirectTo, {
            replace: true,
          });
        }
      }
      if (onSuccessRedirectTo == null || onSuccessRedirectTo === '') {
        navigate('/account', {
          replace: true,
        });
      }
    });
}
