import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import sessionReducer, { Session } from './session';
import modalReducer, { Modal } from './modal';
import pagesReducer, { Pages } from './pages';
import shopReducer, { Shop } from './shop';
import currentUserReducer, { CurrentUser } from './currentUser';
import licensesBySerialReducer, { LicensesBySerial } from './licensesBySerial';
import licensesByIDReducer, { LicensesByID } from './licensesByID';
import ordersByNumberReducer, { OrdersByNumber } from './ordersByNumber';
import ordersByIDReducer, { OrdersByID } from './ordersByID';
import invoicesByNumberReducer, { InvoicesByNumber } from './invoicesByNumber';
import licenseTradeInValueBySerialReducer, {
  LicenseTradeInValueBySerial,
} from './licenseTradeInValueBySerial';
import storeCreditChangesByAccountIDReducer, {
  StoreCreditChangesByAccountID,
} from './storeCreditChangesByAccountID';
import rentalConversionsByIDReducer, {
  RentalConversionsByID,
} from './rentalConversionsByID';
import usersByIDReducer, { UsersByID } from './usersByID';
import accountsByIDReducer, { AccountsByID } from './accountsByID';
import notificationsReducer, { Notifications } from './notifications';
import recaptchaReducer, { Recaptcha } from './recaptcha';
export type AppState = {
  session: Session;
  modal: Modal;
  pages: Pages;
  shop: Shop;
  currentUser: CurrentUser;
  licensesBySerial: LicensesBySerial;
  licensesByID: LicensesByID;
  ordersByNumber: OrdersByNumber;
  ordersByID: OrdersByID;
  invoicesByNumber: InvoicesByNumber;
  licenseTradeInValueBySerial: LicenseTradeInValueBySerial;
  storeCreditChangesByAccountID: StoreCreditChangesByAccountID;
  rentalConversionsByID: RentalConversionsByID;
  usersByID: UsersByID;
  accountsByID: AccountsByID;
  notifications: Notifications;
  recaptcha: Recaptcha;
};
const defaultState = {
  session: undefined,
  modal: undefined,
  pages: undefined,
  currentUser: undefined,
  licensesBySerial: undefined,
  licensesByID: undefined,
  ordersByNumber: undefined,
  ordersByID: undefined,
  invoicesByNumber: undefined,
  licenseTradeInValueBySerial: undefined,
  storeCreditChangesByAccountID: undefined,
  rentalConversionsByID: undefined,
  usersByID: undefined,
  accountsByID: undefined,
  notifications: undefined,
  recaptcha: undefined,
};
export default function reducers(
  state: ReducerInputState<AppState> = defaultState,
  action: Action,
): AppState {
  return {
    session: sessionReducer(state.session, action),
    pages: pagesReducer(state.pages, action),
    modal: modalReducer(state.modal, action),
    shop: shopReducer(state.shop, action),
    currentUser: currentUserReducer(state.currentUser, action),
    licensesBySerial: licensesBySerialReducer(state.licensesBySerial, action),
    licensesByID: licensesByIDReducer(state.licensesByID, action),
    ordersByNumber: ordersByNumberReducer(state.ordersByNumber, action),
    ordersByID: ordersByIDReducer(state.ordersByID, action),
    invoicesByNumber: invoicesByNumberReducer(state.invoicesByNumber, action),
    licenseTradeInValueBySerial: licenseTradeInValueBySerialReducer(
      state.licenseTradeInValueBySerial,
      action,
    ),
    storeCreditChangesByAccountID: storeCreditChangesByAccountIDReducer(
      state.storeCreditChangesByAccountID,
      action,
    ),
    rentalConversionsByID: rentalConversionsByIDReducer(
      state.rentalConversionsByID,
      action,
    ),
    usersByID: usersByIDReducer(state.usersByID, action),
    accountsByID: accountsByIDReducer(state.accountsByID, action),
    notifications: notificationsReducer(state.notifications, action),
    recaptcha: recaptchaReducer(state.recaptcha, action),
  };
}
