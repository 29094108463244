import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Catalog as CatalogValue } from 'types/products';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type Catalog = RemoteData<CatalogValue>;
const defaultState = uninitializedRemoteData<CatalogValue>();
export default function catalogReducer(
  state: ReducerInputState<Catalog> = defaultState,
  action: Action,
): Catalog {
  switch (action.type) {
    case 'FETCH_SHOP_PRODUCTS_START':
      return unfetchedRemoteData<CatalogValue>();
    case 'FETCH_SHOP_PRODUCTS_FAIL':
      return remoteDataError<CatalogValue>(action.message);
    case 'FETCH_SHOP_INFO_SUCCESS':
      return fetchedRemoteData<CatalogValue>({
        byID: action.updatedShopInfo.Catalog.reduce((catalogValue, product) => {
          return Object.assign({}, catalogValue, {
            [product.ID]: product,
          });
        }, {}),
        asArray: action.updatedShopInfo.Catalog,
      });
    default:
      return state;
  }
}
