import backend from 'app/backend';
import { currentState, dispatch } from 'app/store';
import { License } from 'types/license';
export default function ShowLicense(licenseSerial: string) {
  fetchLicense(licenseSerial);
  showLicense(licenseSerial);
}
async function fetchLicense(licenseSerial) {
  let license: License | undefined | null;
  const currentLicenseRemoteData =
    currentState().licensesBySerial[licenseSerial];
  if (currentLicenseRemoteData?.value != null) {
    license = currentLicenseRemoteData.value;
  } else {
    dispatch({
      type: 'FETCH_LICENSE_START',
      licenseSerial,
    });
    const args = {};
    const filter = {
      Serial: licenseSerial,
    };
    try {
      const response = await backend.dispatch(
        'GetLicenseForSessionAccount',
        args,
        filter,
        {
          requireSessionUser: true,
        },
      );
      if (!response.Success) {
        dispatch({
          type: 'FETCH_LICENSE_FAIL',
          licenseSerial,
          message: response.Error,
        });
        return;
      }
      license = response.Result;
    } catch (error) {
      if (error instanceof backend.NoSessionUserError) {
        dispatch({
          type: 'FETCH_LICENSE_FAIL',
          licenseSerial,
          message: 'You must be signed in to view your licenses.',
          error,
        });
        return;
      }
      dispatch({
        type: 'FETCH_LICENSE_FAIL',
        licenseSerial,
        message: error.message,
      });
    }
  }
  if (license != null) {
    dispatch({
      type: 'FETCH_LICENSE_SUCCESS',
      licenseSerial,
      license,
    });
    if (license.Terms === 'site') {
      dispatch({
        type: 'SET_SEATS_TABLE_COLUMN_VISIBILITY',
        columnName: 'SeatID',
        value: true,
      });
    } else {
      dispatch({
        type: 'SET_SEATS_TABLE_COLUMN_VISIBILITY',
        columnName: 'SeatID',
        value: false,
      });
    }

    // we need the order so we can link to it from the license page
    fetchOrderByID(license.OrderID);
  }
}
function fetchOrderByID(OrderID) {
  if (OrderID === 0) {
    return;
  }
  const args = {};
  const filter = {
    OrderID,
  };
  return backend
    .dispatch('GetOrderForSessionAccount', args, filter)
    .then((response) => {
      if (!response.Success) {
        return;
      }
      const order = response.Result;
      dispatch({
        type: 'FETCH_ORDER_SUCCESS',
        orderNumber: order.Number,
        order,
      });
    });
}
function showLicense(licenseSerial) {
  dispatch({
    type: 'SHOW_LICENSE',
    licenseSerial,
  });
}
