import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { License, LicenseSeatActivation } from 'types/license';
import { Product } from 'types/products';
export type UpgradeToV5Page = {
  upgradeStep: 'features' | 'activations' | null;
  licenseSerial: License['Serial'] | undefined | null;
  upgradeTarget: Product | undefined | null;
  selectedActivations:
    | {
        [feature: string]: Set<LicenseSeatActivation['MachineID']>;
      }
    | undefined
    | null;
};
const defaultState = {
  upgradeStep: null,
  licenseSerial: null,
  upgradeTarget: null,
  selectedActivations: null,
};
export default function upgradeToV5PageReducer(
  state: ReducerInputState<UpgradeToV5Page> = defaultState,
  action: Action,
): UpgradeToV5Page {
  switch (action.type) {
    case 'CHOOSE_UPGRADE_TARGET':
      return Object.assign({}, state, {
        upgradeTarget: action.upgradeTarget,
        selectedActivations: null,
      });
    case 'CHOOSE_UPGRADE_ACTIVATIONS':
      return Object.assign({}, state, {
        selectedActivations: action.selectedActivations,
      });
    case 'CLEAR_V5_UPGRADE_STEP':
      return Object.assign({}, state, {
        upgradeStep: null,
        licenseSerial: null,
        upgradeTarget: null,
        selectedActivations: null,
      });
    case 'START_V5_UPGRADE_STEP':
      return Object.assign({}, state, {
        upgradeStep: action.upgradeStep,
        licenseSerial: action.licenseSerial,
      });
    default:
      return state;
  }
}
