import { Account } from 'types/account';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { RemoteData, fetchedRemoteData } from 'app/store/remoteData';
export type AccountsByID = {
  [number: Account['ID']]: RemoteData<Account>;
};
const defaultState = {};
export default function accountsByIDReducer(
  state: ReducerInputState<AccountsByID> = defaultState,
  action: Action,
): AccountsByID {
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'CREATE_USER_ACCOUNT_SUCCESS') {
    return Object.assign({}, state, {
      [action.account.ID]: fetchedRemoteData<Account>(action.account),
    });
  }
  if (action.type === 'FETCH_SHOP_INFO_SUCCESS') {
    const accountUpdates = action.updatedShopInfo.Account;
    if (accountUpdates) {
      return Object.assign({}, state, {
        [accountUpdates.ID]: fetchedRemoteData<Account>(accountUpdates),
      });
    }
  }
  if (action.type === 'ADD_PRODUCT_TO_CART_FAIL') {
    const accountUpdates = action.updatedShopInfo.Account;
    if (accountUpdates) {
      return Object.assign({}, state, {
        [accountUpdates.ID]: fetchedRemoteData<Account>(accountUpdates),
      });
    }
  }
  if (action.type === 'TRADE_IN_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.account.ID]: fetchedRemoteData<Account>(action.account),
    });
  }
  if (action.type === 'FETCH_ACCOUNT_SUCCESS') {
    return Object.assign({}, state, {
      [action.account.ID]: fetchedRemoteData<Account>(action.account),
    });
  }
  return state;
}
