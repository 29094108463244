import backend from 'app/backend';
import { currentState, dispatch } from 'app/store';
type Options = {
  forceRefresh?: boolean;
};
const defaultOptions: Options = {
  forceRefresh: false,
};
export default function GetShopInfo(options: Options = defaultOptions) {
  const { forceRefresh = defaultOptions.forceRefresh } = options;

  // avoid redundant fetch
  const remoteData = currentState().shop.cart;
  if (!forceRefresh && (remoteData.fetched || remoteData.pendingResponse)) {
    return;
  }
  dispatch({
    type: 'INITIALIZE_CART_START',
  });
  dispatch({
    type: 'FETCH_SHOP_PRODUCTS_START',
  });
  dispatch({
    type: 'FETCH_LEGACY_LICENSES_START',
  });
  dispatch({
    type: 'INITIALIZE_RENTAL_STATS_START',
  });
  const args = {};
  backend.dispatch('GetShopInfo', args).then((response) => {
    if (!response.Success) {
      dispatch({
        type: 'INITIALIZE_CART_FAIL',
        message: response.Error,
      });
      dispatch({
        type: 'FETCH_SHOP_PRODUCTS_FAIL',
        message: response.Error,
      });
      dispatch({
        type: 'FETCH_LEGACY_LICENSES_FAIL',
        message: response.Error,
      });
      dispatch({
        type: 'INITIALIZE_RENTAL_STATS_FAIL',
        message: response.Error,
      });
      return;
    }
    dispatch({
      type: 'FETCH_SHOP_INFO_SUCCESS',
      updatedShopInfo: response.Result,
    });
  });
}
