import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  fetchedRemoteData,
  RemoteData,
} from 'app/store/remoteData';
export type Validators = {
  maximumSurveyResponseLength: RemoteData<number>;
};
const defaultState = {
  maximumSurveyResponseLength: uninitializedRemoteData<number>(),
};
export default function validatorsReducer(
  state: ReducerInputState<Validators> = defaultState,
  action: Action,
): Validators {
  switch (action.type) {
    case 'FETCH_SHOP_INFO_SUCCESS':
      return Object.assign({}, state, {
        maximumSurveyResponseLength: fetchedRemoteData(
          action.updatedShopInfo.MaximumSurveyResponseLength,
        ),
      });
    default:
      return state;
  }
}
