import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import cartLineItemErrorMessagesReducer, {
  CartLineItemErrorMessages,
} from './cartLineItemErrorMessages';
export type CartPage = {
  cartLineItemErrorMessages: CartLineItemErrorMessages;
};
const defaultState = {
  cartLineItemErrorMessages: undefined,
};
export default function cartPageReducer(
  state: ReducerInputState<CartPage> = defaultState,
  action: Action,
): CartPage {
  return {
    cartLineItemErrorMessages: cartLineItemErrorMessagesReducer(
      state.cartLineItemErrorMessages,
      action,
    ),
  };
}
