export type RemoteData<T> = {
  value: T | undefined | null;
  pendingResponse: boolean;
  fetched: boolean;
  updatingExisting: boolean;
  errorMessage: string | undefined | null;
  error: Error | undefined | null;
};

// return a representation of remote data that we haven't requested yet
export function uninitializedRemoteData<T>(): RemoteData<T> {
  return {
    value: null,
    pendingResponse: false,
    fetched: false,
    updatingExisting: false,
    errorMessage: null,
    error: null,
  };
}

// return a representation of remote data that will be fetched
export function unfetchedRemoteData<T>(): RemoteData<T> {
  return {
    value: null,
    pendingResponse: true,
    fetched: false,
    updatingExisting: false,
    errorMessage: null,
    error: null,
  };
}

// return a RemoteData-wrapped value that has been fetched successfully
export function fetchedRemoteData<T>(value: T): RemoteData<T> {
  return {
    value,
    pendingResponse: false,
    fetched: true,
    updatingExisting: false,
    errorMessage: null,
    error: null,
  };
}

// alias for unfetchedRemoteData
export const pendingRemoteData = unfetchedRemoteData;

// return successfully fetched remote data, which included an error message
//
// e.g.
// we try to add an item to the shopping cart, and the server responds with an
// error because the item we tried to add is invalid; we don't want to clobber
// the cart, we just want to add an error message to it
export function remoteDataUpdateError<T>(
  oldValue: T | undefined | null,
  errorMessage: string,
  error: Error | undefined | null = null,
): RemoteData<T> {
  return {
    value: oldValue,
    pendingResponse: false,
    fetched: true,
    updatingExisting: false,
    errorMessage,
    error,
  };
}

// return a RemoteData-wrapped value whose value will be replaced by an updated version
// (e.g. an existing cart about to be updated)
export function updatingRemoteData<T>(currentValue?: T | null): RemoteData<T> {
  return {
    value: currentValue,
    pendingResponse: true,
    fetched: true,
    updatingExisting: true,
    errorMessage: null,
    error: null,
  };
}

// when we totally fail to fetch remote data
export function remoteDataError<T>(
  errorMessage: string,
  error: Error | undefined | null = null,
): RemoteData<T> {
  return {
    value: null,
    pendingResponse: false,
    fetched: true,
    updatingExisting: false,
    errorMessage,
    error,
  };
}
