import { Invoice } from 'types/invoice';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  fetchedRemoteData,
  unfetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type InvoicesByNumber = {
  [number: Invoice['Number']]: RemoteData<Invoice>;
};
const defaultState = {};
export default function invoicesByNumberReducer(
  state: ReducerInputState<InvoicesByNumber> = defaultState,
  action: Action,
): InvoicesByNumber {
  if (action.type === 'FETCH_INVOICE_START') {
    return Object.assign({}, state, {
      [action.invoiceNumber]: unfetchedRemoteData<Invoice>(),
    });
  }
  if (action.type === 'FETCH_INVOICE_SUCCESS') {
    return Object.assign({}, state, {
      [action.invoiceNumber]: fetchedRemoteData<Invoice>(action.invoice),
    });
  }
  if (action.type === 'FETCH_INVOICE_FAIL') {
    return Object.assign({}, state, {
      [action.invoiceNumber]: remoteDataError<Invoice>(
        action.message,
        action.error,
      ),
    });
  }
  if (action.type === 'FETCH_SESSION_INVOICES_SUCCESS') {
    const { invoicesByNumber, fetchedNumbers } = action;
    const fetchedInvoices: {
      [invoiceNumber: Invoice['Number']]: RemoteData<Invoice>;
    } = {};
    fetchedNumbers.forEach((number) => {
      fetchedInvoices[number] = fetchedRemoteData<Invoice>(
        invoicesByNumber[number],
      );
    });
    return Object.assign({}, state, fetchedInvoices);
  }
  if (action.type === 'UPDATE_INVOICE_FOR_CHECKOUT_SUCCESS') {
    const { updatedInvoice } = action;
    return Object.assign({}, state, {
      [updatedInvoice.Number]: fetchedRemoteData<Invoice>(updatedInvoice),
    });
  }
  if (action.type === 'INVOICE_CHECKOUT_FAIL') {
    const { updatedInvoice } = action;
    if (updatedInvoice != null) {
      return Object.assign({}, state, {
        [updatedInvoice.Number]: fetchedRemoteData<Invoice>(updatedInvoice),
      });
    }
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
