import { User } from 'types/user';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { RemoteData, fetchedRemoteData } from 'app/store/remoteData';
export type UsersByID = {
  [number: User['ID']]: RemoteData<User>;
};
const defaultState = {};
export default function usersByIDReducer(
  state: ReducerInputState<UsersByID> = defaultState,
  action: Action,
): UsersByID {
  if (action.type === 'CREATE_USER_ACCOUNT_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
      [action.authenticatingUser.ID]: fetchedRemoteData<User>(
        action.authenticatingUser,
      ),
    });
  }
  if (action.type === 'SWITCH_USER_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  if (action.type === 'FETCH_SHOP_INFO_SUCCESS') {
    const userUpdates = action.updatedShopInfo.User;
    if (userUpdates) {
      return Object.assign({}, state, {
        [userUpdates.ID]: fetchedRemoteData<User>(userUpdates),
      });
    }
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'FINALIZE_EMAIL_ADDRESS_CHANGE_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  if (action.type === 'CANCEL_EMAIL_ADDRESS_CHANGE_REQUEST_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  if (action.type === 'CHANGE_USER_NAME_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  if (action.type === 'CHANGE_USER_PASSWORD_SUCCESS') {
    return Object.assign({}, state, {
      [action.user.ID]: fetchedRemoteData<User>(action.user),
    });
  }
  return state;
}
