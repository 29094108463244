import { License } from 'types/license';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { fetchedRemoteData, RemoteData } from 'app/store/remoteData';
export type LicensesByID = {
  [ID: License['ID']]: RemoteData<License>;
};
const defaultState = {};
export default function licensesByIDReducer(
  state: ReducerInputState<LicensesByID> = defaultState,
  action: Action,
): LicensesByID {
  if (action.type === 'FETCH_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.license.ID]: fetchedRemoteData<License>(action.license),
    });
  }
  if (action.type === 'FETCH_SESSION_LICENSES_SUCCESS') {
    const fetchedLicenses: LicensesByID = {};
    action.licenses.forEach((license) => {
      fetchedLicenses[license.ID] = fetchedRemoteData<License>(license);
    });
    return Object.assign({}, state, fetchedLicenses);
  }
  if (action.type === 'TRADE_IN_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.license.ID]: fetchedRemoteData<License>(action.license),
    });
  }
  if (action.type === 'UPDATE_LICENSE') {
    return Object.assign({}, state, {
      [action.updatedLicense.Serial]: fetchedRemoteData<License>(
        action.updatedLicense,
      ),
    });
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
