import { Order } from 'types/order';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  fetchedRemoteData,
  unfetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type OrdersByNumber = {
  [number: Order['Number']]: RemoteData<Order>;
};
const defaultState = {};
export default function ordersByNumberReducer(
  state: ReducerInputState<OrdersByNumber> = defaultState,
  action: Action,
): OrdersByNumber {
  if (action.type === 'FETCH_ORDER_START') {
    return Object.assign({}, state, {
      [action.orderNumber]: unfetchedRemoteData<Order>(),
    });
  }
  if (action.type === 'FETCH_ORDER_SUCCESS') {
    return Object.assign({}, state, {
      [action.orderNumber]: fetchedRemoteData<Order>(action.order),
    });
  }
  if (action.type === 'FETCH_ORDER_FAIL') {
    return Object.assign({}, state, {
      [action.orderNumber]: remoteDataError<Order>(
        action.message,
        action.error,
      ),
    });
  }
  if (action.type === 'FETCH_SESSION_ORDERS_SUCCESS') {
    const { ordersByNumber, fetchedNumbers } = action;
    const fetchedOrders: {
      [orderNumber: Order['Number']]: RemoteData<Order>;
    } = {};
    fetchedNumbers.forEach((number) => {
      fetchedOrders[number] = fetchedRemoteData<Order>(ordersByNumber[number]);
    });
    return Object.assign({}, state, fetchedOrders);
  }
  if (action.type === 'TRADE_IN_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.order.Number]: fetchedRemoteData<Order>(action.order),
    });
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
