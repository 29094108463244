import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import ordersReducer, { Orders } from './orders';
import invoicesReducer, { Invoices } from './invoices';
import licensesReducer, { Licenses } from './licenses';
export type CurrentUser = {
  orders: Orders;
  invoices: Invoices;
  licenses: Licenses;
};
const defaultState = {
  orders: undefined,
  invoices: undefined,
  licenses: undefined,
};
export default function currentUserReducer(
  state: ReducerInputState<CurrentUser> = defaultState,
  action: Action,
): CurrentUser {
  return {
    orders: ordersReducer(state.orders, action),
    invoices: invoicesReducer(state.invoices, action),
    licenses: licensesReducer(state.licenses, action),
  };
}
