import ShowLicense from 'app/actionCreators/ShowLicense';
import ShowOrder from 'app/actionCreators/ShowOrder';
import ShowInvoice from 'app/actionCreators/ShowInvoice';
import ValidateEmailAddressChangeRequest from 'app/actionCreators/ValidateEmailAddressChangeRequest';
import ValidateAuthorizedEmailAddressChangeRequest from 'app/actionCreators/ValidateAuthorizedEmailAddressChangeRequest';
import VerifyLoginEmail from 'app/actionCreators/VerifyLoginEmail';
import CreateAccountViaFancyLogin from 'app/actionCreators/CreateAccountViaFancyLogin';
import { dispatch } from 'app/store';
export type RouteMatches = {
  index: number;
  input: string;
};
export type PathRoute = (pathMatches: RouteMatches) => any | undefined | null;

// NB: client-side only routes must also be configured in `gatsby-node.js`, and
// deployments must have corresponding NGINX configuration (see `gatsby-node.js` for details)

const ROUTES: Array<[RegExp, PathRoute]> = [
  // authorize email address change (from the old email address)
  [
    /^\/confirm\/email-address-change\/?$/,
    () => {
      const searchParams = new URLSearchParams(window.location.search);
      ValidateEmailAddressChangeRequest(
        searchParams.get('useremail'),
        searchParams.get('token'),
      );
    },
  ],
  // finalize email address change (from the new email address)
  [
    /^\/confirm\/new-email-address\/?$/,
    () => {
      const searchParams = new URLSearchParams(window.location.search);
      ValidateAuthorizedEmailAddressChangeRequest(
        searchParams.get('useremail'),
        searchParams.get('token'),
      );
    },
  ],
  // email verification
  [
    /^\/account\/verify-email\/([A-Za-z0-9_-]+)$/,
    (pathMatches: RouteMatches) => {
      const Token = pathMatches[1];
      const searchParams = new URLSearchParams(window.location.search);
      VerifyLoginEmail({
        Token,
        onSuccessRedirectTo: searchParams.get('referrer'),
      });
    },
  ],
  // registration links
  // e.g. https://qlab.app/account/register/l8iSfl6tV73S66jyY36rEpKE0Rs5_egY0e_oGNJk-O0
  [
    /^\/account\/register\/([A-Za-z0-9_-]+)\/?$/,
    (pathMatches: RouteMatches) => {
      const Token = pathMatches[1];
      const searchParams = new URLSearchParams(window.location.search);
      CreateAccountViaFancyLogin({
        Token,
        onSuccessRedirectTo: searchParams.get('referrer'),
      });
    },
  ],
  [
    /^\/account\/licenses\/([A-Za-z0-9-]+)\/?$/,
    (pathMatches: RouteMatches) => {
      const licenseSerial = pathMatches[1];
      ShowLicense(licenseSerial);
    },
  ],
  [
    /^\/account\/orders\/([A-Za-z0-9-]+)(\/success)?\/?$/,
    (pathMatches: RouteMatches) => {
      const orderNumber = pathMatches[1];
      ShowOrder(orderNumber);
    },
  ],
  [
    /^\/account\/order-print\/([A-Za-z0-9-]+)?\/?$/,
    (pathMatches: RouteMatches) => {
      const orderNumber = pathMatches[1];
      ShowOrder(orderNumber);
    },
  ],
  [
    /^\/account\/invoices\/([A-Za-z0-9-]+)?\/?$/,
    (pathMatches: RouteMatches) => {
      const invoiceNumber = pathMatches[1];
      ShowInvoice(invoiceNumber);
    },
  ],
  [
    /^\/account\/invoice-print\/([A-Za-z0-9-]+)?\/?$/,
    (pathMatches: RouteMatches) => {
      const invoiceNumber = pathMatches[1];
      ShowInvoice(invoiceNumber);
    },
  ],
  [
    /^\/upgrade-to-v5\/?$/,
    () => {
      dispatch({
        type: 'CLEAR_V5_UPGRADE_STEP',
      });
    },
  ],
  [
    /^\/upgrade-to-v5\/([A-Za-z0-9-]+)\/(features|activations|review)\/?$/,
    (pathMatches: RouteMatches) => {
      const licenseSerial = pathMatches[1];
      const upgradeStep = pathMatches[2];
      dispatch({
        type: 'START_V5_UPGRADE_STEP',
        licenseSerial,
        upgradeStep,
      });
    },
  ],
];
export function execute(path: string) {
  const matchingRoute = ROUTES.find((routeTuple) => {
    const matcher = routeTuple[0];
    return matcher.exec(path);
  });
  if (!matchingRoute) {
    return;
  }
  const matcher = matchingRoute[0];
  const runRoute = matchingRoute[1];
  const pathMatches = matcher.exec(path);
  if (pathMatches !== null) {
    runRoute(pathMatches);
  }
}
export default {
  execute,
};
