import { dispatch } from 'app/store';

// should be slightly longer than dismiss animation.
// see: src/components/Notifications.scss
const purgeDelayMs = 600;
export default function DismissNotification(id: string) {
  dispatch({
    type: 'DISMISS_NOTIFICATION',
    id,
  });
  setTimeout(() => {
    dispatch({
      type: 'PURGE_NOTIFICATION',
      id,
    });
  }, purgeDelayMs);
}
