import backend from 'app/backend';
import { currentState, dispatch } from 'app/store';
export default function ShowOrder(orderNumber: string) {
  fetchOrder(orderNumber);
  showOrder(orderNumber);
}
function fetchOrder(orderNumber: string) {
  if (currentState().ordersByNumber[orderNumber]) {
    return;
  }
  dispatch({
    type: 'FETCH_ORDER_START',
    orderNumber,
  });
  const args = {};
  const filter = {
    OrderNumber: orderNumber,
  };
  return backend
    .dispatch('GetOrderForSessionAccount', args, filter, {
      requireSessionUser: true,
    })
    .then((response) => {
      if (!response.Success) {
        dispatch({
          type: 'FETCH_ORDER_FAIL',
          orderNumber,
          message: response.Error,
        });
        return;
      }
      dispatch({
        type: 'FETCH_ORDER_SUCCESS',
        orderNumber,
        order: response.Result,
      });
    })
    .catch((error) => {
      if (error instanceof backend.NoSessionUserError) {
        dispatch({
          type: 'FETCH_ORDER_FAIL',
          orderNumber,
          message: 'You must be signed in to view your orders.',
          error,
        });
        return;
      }
      dispatch({
        type: 'FETCH_ORDER_FAIL',
        orderNumber,
        message: error.message,
      });
    });
}
function showOrder(orderNumber: string) {
  dispatch({
    type: 'SHOW_ORDER',
    orderNumber,
  });
}
