import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type SignInPage = {
  processingFancyLogin: boolean;
  processingFancyLoginReason: string;
  signInRequest: RemoteData<void>;
  signUpRequest: RemoteData<string>;
};
const defaultState = {
  processingFancyLogin: false,
  processingFancyLoginReason: '',
  signInRequest: uninitializedRemoteData<void>(),
  signUpRequest: uninitializedRemoteData<string>(),
};
export default function accountPageReducer(
  state: ReducerInputState<SignInPage> = defaultState,
  action: Action,
): SignInPage {
  switch (action.type) {
    case 'PROCESSING_FANCY_LOGIN_START':
      return Object.assign({}, state, {
        processingFancyLogin: true,
        processingFancyLoginReason: action.reason,
      });
    case 'PROCESSING_FANCY_LOGIN_END':
      return Object.assign({}, state, {
        processingFancyLogin: false,
        processingFancyLoginReason: '',
      });
    case 'SIGN_IN_START':
      return Object.assign({}, state, {
        signInRequest: unfetchedRemoteData<void>(),
      });
    case 'SIGN_IN_SUCCESS':
      return Object.assign({}, state, {
        signInRequest: fetchedRemoteData<void>(),
      });
    case 'SIGN_IN_FAIL':
      return Object.assign({}, state, {
        signInRequest: remoteDataError<void>(action.message),
      });
    case 'REQUEST_USER_ACCOUNT_START':
      return Object.assign({}, state, {
        signUpRequest: unfetchedRemoteData<string>(),
      });
    case 'REQUEST_USER_ACCOUNT_SUCCESS':
      return Object.assign({}, state, {
        signUpRequest: fetchedRemoteData<string>(action.message),
      });
    case 'REQUEST_USER_ACCOUNT_FAIL':
      return Object.assign({}, state, {
        signUpRequest: remoteDataError<string>(action.message),
      });
    case 'SIGN_OUT_SUCCESS':
      return Object.assign({}, state, defaultState);
    case 'RESET_SIGN_IN_FORM':
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
}
