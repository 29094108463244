import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import { Product } from 'types/products';
import {
  uninitializedRemoteData,
  unfetchedRemoteData,
  fetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
type ProductsValue = Array<Product>;
export type Products = RemoteData<ProductsValue>;
const defaultState = uninitializedRemoteData<ProductsValue>();
export default function productsReducer(
  state: ReducerInputState<Products> = defaultState,
  action: Action,
): Products {
  switch (action.type) {
    case 'FETCH_SHOP_PRODUCTS_START':
      return unfetchedRemoteData<ProductsValue>();
    case 'FETCH_SHOP_PRODUCTS_FAIL':
      return remoteDataError<ProductsValue>(action.message);
    case 'FETCH_SHOP_INFO_SUCCESS':
      return fetchedRemoteData<ProductsValue>(
        productsForSale(action.updatedShopInfo.Catalog),
      );
    case 'ADD_PRODUCT_TO_CART_FAIL':
      if (action.updatedShopInfo.Catalog) {
        return fetchedRemoteData<ProductsValue>(
          productsForSale(action.updatedShopInfo.Catalog),
        );
      }
      return state;
    default:
      return state;
  }
}
function productsForSale(catalog: Array<Product>): Array<Product> {
  return catalog.filter((product) => {
    return (
      product.Available &&
      product.LicenseSpec.App === 'QLab' &&
      String(product.LicenseSpec.AppVersion) ===
        String(ENV.QLAB_CURRENT_MAJOR_VERSION) // ensure type match
    );
  });
}
