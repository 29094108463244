import { License } from 'types/license';
import { Action } from 'app/store/actions';
import { ReducerInputState } from 'app/store/types';
import {
  fetchedRemoteData,
  unfetchedRemoteData,
  remoteDataError,
  RemoteData,
} from 'app/store/remoteData';
export type LicensesBySerial = {
  [serialNumber: string]: RemoteData<License>;
};
const defaultState = {};
export default function licensesBySerialReducer(
  state: ReducerInputState<LicensesBySerial> = defaultState,
  action: Action,
): LicensesBySerial {
  if (action.type === 'FETCH_LICENSE_START') {
    return Object.assign({}, state, {
      [action.licenseSerial]: unfetchedRemoteData<License>(),
    });
  }
  if (action.type === 'FETCH_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.licenseSerial]: fetchedRemoteData<License>(action.license),
    });
  }
  if (action.type === 'UPDATE_LICENSE') {
    return Object.assign({}, state, {
      [action.updatedLicense.Serial]: fetchedRemoteData<License>(
        action.updatedLicense,
      ),
    });
  }
  if (action.type === 'FETCH_LICENSE_FAIL') {
    return Object.assign({}, state, {
      [action.licenseSerial]: remoteDataError(action.message, action.error),
    });
  }
  if (action.type === 'FETCH_SESSION_LICENSES_SUCCESS') {
    const fetchedLicenses: LicensesBySerial = {};
    action.licenses.forEach((license) => {
      fetchedLicenses[license.Serial] = fetchedRemoteData<License>(license);
    });
    return Object.assign({}, state, fetchedLicenses);
  }
  if (action.type === 'TRADE_IN_LICENSE_SUCCESS') {
    return Object.assign({}, state, {
      [action.license.Serial]: fetchedRemoteData<License>(action.license),
    });
  }
  if (action.type === 'SIGN_OUT_SUCCESS') {
    return {};
  }
  if (action.type === 'SIGN_IN_SUCCESS') {
    return {};
  }
  return state;
}
